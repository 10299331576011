import Header from './Header'
import Footer from './Footer'
import { GoogleTagManager } from '@next/third-parties/google';

// mt-5 pt-4 are needed because of the fixed header
export default function Layout({children}) {
    return (
        <>
            <Header/>
            <main className={/*'mt-5 pt-4'*/ 'mt-3'}>{children}</main>
            <Footer/>
            <GoogleTagManager gtmId="GTM-TDDQFCS5" />
        </>
    )
}
