// A loadLocaleFrom paraméter valamiért nem működik a next-translate csomagban...
// Egyelőre van rá egy workaround (https://github.com/aralroca/next-translate/issues/851#issuecomment-1173611946):
// const workaround = require("next-translate/lib/cjs/plugin/utils.js")
// ez az eredeti - console.log(workaround.defaultLoader)
// workaround.defaultLoader = "(l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)"
// workaround.defaultLoader = '(l, n) => import(`@next-translate-root/src/locales/${n}/${l}.json`).then(m => m.default)';

module.exports = {
    locales: [
        // 'en-US',

        /**/
        'af-ZA',
        'ar-AE',
        'ar-SA',
        'be-BY',
        'bg-BG',
        'bn-BD',
        'ca-ES',
        'ch-GU',
        // 'cn-CN', // invalid language code cd-CN, do not enable this
        'cs-CZ',
        'cy-GB',
        'da-DK',
        'de-AT',
        'de-CH',
        'de-DE',
        'el-GR',
        'en-AU',
        'en-CA',
        'en-GB',
        'en-IE',
        'en-NZ',
        'en-US',
        'eo-EO',
        'es-ES',
        'es-MX',
        'et-EE',
        'eu-ES',
        'fa-IR',
        'fi-FI',
        'fr-CA',
        'fr-FR',
        'ga-IE',
        'gd-GB',
        'gl-ES',
        'he-IL',
        'hi-IN',
        'hr-HR',
        'hu-HU',
        'id-ID',
        'it-IT',
        'ja-JP',
        'ka-GE',
        'kk-KZ',
        'kn-IN',
        'ko-KR',
        'ky-KG',
        'lt-LT',
        'lv-LV',
        'ml-IN',
        'mr-IN',
        'ms-MY',
        'ms-SG',
        'nb-NO',
        'nl-BE',
        'nl-NL',
        'no-NO',
        'pa-IN',
        'pl-PL',
        'pt-BR',
        'pt-PT',
        'ro-RO',
        'ru-RU',
        'si-LK',
        'sk-SK',
        'sl-SI',
        'sq-AL',
        'sr-RS',
        'sv-SE',
        'ta-IN',
        'te-IN',
        'th-TH',
        'tl-PH',
        'tr-TR',
        'uk-UA',
        'vi-VN',
        'zh-CN',
        'zh-HK',
        'zh-SG',
        'zh-TW',
        'zu-ZA'
        /**/
    ],
    defaultLocale: 'en-US',
    pages: {
        '*': ['common'],
    },
    // "localeDetection": false,
    // TODO: temporary disabled translation logging, eg missing translations
    logger: () => {
    },
}
