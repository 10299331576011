import getConfig from 'next/config';
import {
    Cast,
    ConfigurationResponse,
    CreditsResponse, Crew,
    Language,
    MovieReleaseDatesResponse,
    MovieResponse,
    MovieResult, Network,
    Person,
    PersonCombinedCreditsResponse,
    PersonImagesResponse,
    ReleaseDate,
    TvResult,
    WatchProvider,
} from 'moviedb-promise/dist/request-types';

const {publicRuntimeConfig} = getConfig()

import useTranslation from 'next-translate/useTranslation'

// use luxon instead of date-fns
// import {format, differenceInYears} from 'date-fns';
// import format from "date-fns/fp/format"
// import differenceInYears from "date-fns/fp/differenceInYears"

import { DateTime } from 'luxon';

const movieBasePaths = publicRuntimeConfig.movieBasePathsObject
const tvShowBasePaths = publicRuntimeConfig.tvShowBasePathsObject
const personBasePaths = publicRuntimeConfig.personBasePathsObject

// TODO: ezeket az interfaceket egy saját type fájlban kellene deklarálni
// https://stackoverflow.com/a/70649413/4510591

// missing type from moviedb-promise package
interface PersonCombinedCreditsResponseCrew {
    id?: number;
    original_language?: string;
    episode_count?: number;
    overview?: string;
    origin_country?: string[];
    original_name?: string;
    genre_ids?: number[];
    name?: string;
    media_type?: string;
    poster_path?: string | null;
    first_air_date?: string;
    vote_average?: number;
    vote_count?: number;
    character?: string;
    backdrop_path?: string | null;
    popularity?: number;
    credit_id?: string;
    original_title?: string;
    video?: boolean;
    release_date?: string;
    title?: string;
    adult?: boolean;
}

// missing type from moviedb-promise package
interface PersonCombinedCreditsResponseCast {
    id?: number;
    department?: string;
    original_language?: string;
    episode_count?: number;
    job?: string;
    overview?: string;
    origin_country?: string[];
    original_name?: string;
    vote_count?: number;
    name?: string;
    media_type?: string;
    popularity?: number;
    credit_id?: string;
    backdrop_path?: string | null;
    first_air_date?: string;
    vote_average?: number;
    genre_ids?: number[];
    poster_path?: string | null;
    original_title?: string;
    video?: boolean;
    title?: string;
    adult?: boolean;
    release_date?: string;
}

// missing type from moviedb-promise package
interface WatchProviderDisplayPriorities {
    AR?: number;
    AT?: number;
    AU?: number;
    BE?: number;
    BR?: number;
    CA?: number;
    CH?: number;
    CL?: number;
    CO?: number;
    CZ?: number;
    DE?: number;
    DK?: number;
    EC?: number;
    EE?: number;
    ES?: number;
    FI?: number;
    FR?: number;
    GB?: number;
    GR?: number;
    HU?: number;
    ID?: number;
    IE?: number;
    IN?: number;
    IT?: number;
    JP?: number;
    KR?: number;
    LT?: number;
    LV?: number;
    MX?: number;
    MY?: number;
    NL?: number;
    NO?: number;
    NZ?: number;
    PE?: number;
    PH?: number;
    PL?: number;
    PT?: number;
    RO?: number;
    RU?: number;
    SE?: number;
    SG?: number;
    TH?: number;
    TR?: number;
    US?: number;
    VE?: number;
    ZA?: number;
}

const releaseDateTypes = {
    1: 'Premiere',
    2: 'Theatrical (limited)',
    3: 'Theatrical',
    4: 'Digital',
    5: 'Physical',
    6: 'TV',
}

// useTranslation() can only be called within a function component
const GetLocale = (): string => {
    return useTranslation().lang
}

export default class Utils {
    public static isMovie(entity: MovieResult | TvResult | MovieResponse & { release_dates: MovieReleaseDatesResponse, credits: CreditsResponse }):
            entity is MovieResult | & { release_dates: MovieReleaseDatesResponse, credits: CreditsResponse } {
        return ('media_type' in entity && entity.media_type === 'movie') || ('title' in entity)
    }

    public static isTv(entity: MovieResult | TvResult | MovieResponse & { release_dates: MovieReleaseDatesResponse, credits: CreditsResponse }):
            entity is TvResult | MovieResponse & { release_dates: MovieReleaseDatesResponse, credits: CreditsResponse } {
        return ('media_type' in entity && entity.media_type === 'tv') || ('name' in entity)
    }

    public static getEntityUrl(entity: MovieResult | TvResult | MovieResponse & { release_dates: MovieReleaseDatesResponse, credits: CreditsResponse }): string {
        const locale = Utils.getLocale()

        let basePaths
        if (Utils.isMovie(entity)) {
            basePaths = movieBasePaths
        } else {
            basePaths = tvShowBasePaths
        }

        if (locale in basePaths) {
            // return `/${encodeURI(basePaths[locale])}/${entity.id}/${encodeURI(Utils.getEntityTitle(entity))}`
            return `/${encodeURI(basePaths[locale])}/${entity.id}` // TODO: removing title from url, using raw entity urls to get to production earlier - search for this in my code: USRAENUR
        } else {
            // return `/${encodeURI(basePaths['en-US'])}/${entity.id}/${encodeURI(Utils.getEntityTitle(entity))}`
            return `/${encodeURI(basePaths['en-US'])}/${entity.id}` // TODO: removing title from url, using raw entity urls to get to production earlier - search for this in my code: USRAENUR
        }
    }

    public static getMovieUrlByIdAndTitle(id: number, title: string): string {
        const locale = Utils.getLocale()
        const basePaths = movieBasePaths
        if (locale in basePaths) {
            // return `/${encodeURI(basePaths[locale])}/${id}/${encodeURI(title)}`
            return `/${encodeURI(basePaths[locale])}/${id}` // TODO: removing title from url, using raw entity urls to get to production earlier - search for this in my code: USRAENUR
        } else {
            // return `/${encodeURI(basePaths['en-US'])}/${id}/${encodeURI(title)}`
            return `/${encodeURI(basePaths['en-US'])}/${id}` // TODO: removing title from url, using raw entity urls to get to production earlier - search for this in my code: USRAENUR
        }
    }

    public static getTvShowUrlByIdAndName(id: number, name: string): string {
        const locale = Utils.getLocale()
        const basePaths = tvShowBasePaths
        if (locale in basePaths) {
            // return `/${encodeURI(basePaths[locale])}/${id}/${encodeURI(name)}`
            return `/${encodeURI(basePaths[locale])}/${id}` // TODO: removing name from url, using raw entity urls to get to production earlier - search for this in my code: USRAENUR
        } else {
            // return `/${encodeURI(basePaths['en-US'])}/${id}/${encodeURI(name)}`
            return `/${encodeURI(basePaths['en-US'])}/${id}` // TODO: removing name from url, using raw entity urls to get to production earlier - search for this in my code: USRAENUR
        }
    }

    public static getEntityImageUrl(entity: MovieResult | TvResult | MovieResponse & { release_dates: MovieReleaseDatesResponse, credits: CreditsResponse }, size: number = 2): string {
        const movieDbConfig = Utils.getMovieDbConfig()
        return movieDbConfig.images.secure_base_url + movieDbConfig.images.poster_sizes[size] + entity.poster_path
    }

    public static getEntityBackdropImageUrl(entity: MovieResult | TvResult | MovieResponse & { release_dates: MovieReleaseDatesResponse, credits: CreditsResponse }, size: number = 2): string | undefined {
        if (entity.backdrop_path == undefined) {
            return undefined
        }
        const movieDbConfig = Utils.getMovieDbConfig()
        return movieDbConfig.images.secure_base_url + movieDbConfig.images.backdrop_sizes[size] + entity.backdrop_path
    }

    public static getCastImageUrl(cast: Cast, size: number = 2): string {
        const movieDbConfig = Utils.getMovieDbConfig()
        return movieDbConfig.images.secure_base_url + movieDbConfig.images.poster_sizes[size] + cast.profile_path
    }

    public static getCastBackdropImageUrl(cast: Cast, size: number = 2): string | undefined {
        if (cast.profile_path == undefined) {
            return undefined
        }
        const movieDbConfig = Utils.getMovieDbConfig()
        return movieDbConfig.images.secure_base_url + movieDbConfig.images.poster_sizes[size] + cast.profile_path
    }

    public static getNetworkImageUrl(network: Network, size: number = 2): string {
        const movieDbConfig = Utils.getMovieDbConfig()
        return movieDbConfig.images.secure_base_url + movieDbConfig.images.poster_sizes[size] + network.logo_path
    }

    public static getWatchProviderImageUrl(watchProvider: WatchProvider, size: number = 2): string {
        const movieDbConfig = Utils.getMovieDbConfig()
        return movieDbConfig.images.secure_base_url + movieDbConfig.images.logo_sizes[size] + watchProvider.logo_path
    }

    public static getEntityTitle(entity: MovieResult | TvResult | MovieResponse & { release_dates: MovieReleaseDatesResponse, credits: CreditsResponse }): string {
        if (Utils.isMovie(entity)) {
            return entity.title
        }
        return entity.name
    }

    public static getEntityDateString(entity: MovieResult | TvResult | MovieResponse & { release_dates: MovieReleaseDatesResponse, credits: CreditsResponse }): string | undefined {
        if (Utils.isMovie(entity)) {
            return entity.release_date !== '' ? entity.release_date : undefined
        }
        return entity.first_air_date !== '' ? entity.first_air_date : undefined
    }

    public static getPersonUrl(entity: Crew | Cast): string {
        const locale = Utils.getLocale()
        const basePaths = personBasePaths
        if (locale in basePaths) {
            // return `/${encodeURI(basePaths[locale])}/${entity.id}/${encodeURI(Utils.getPersonName(entity))}`
            return `/${encodeURI(basePaths[locale])}/${entity.id}` // TODO: removing name from url, using raw entity urls to get to production earlier - search for this in my code: USRAENUR
        } else {
            // return `/${encodeURI(basePaths['en-US'])}/${entity.id}/${encodeURI(Utils.getPersonName(entity))}`
            return `/${encodeURI(basePaths['en-US'])}/${entity.id}` // TODO: removing name from url, using raw entity urls to get to production earlier - search for this in my code: USRAENUR
        }
    }

    public static getPersonName(entity: Crew | Cast): string {
        return entity.name
    }

    public static getMovieOrTvShowUrlByCredit(credit: PersonCombinedCreditsResponseCrew | PersonCombinedCreditsResponseCast): string {
        if (!('media_type' in credit) || !('id' in credit) || (!('title' in credit) && !('name' in credit))) {
            return 'unknown'
        }
        if (credit.media_type === 'movie') {
            return Utils.getMovieUrlByIdAndTitle(credit.id, credit.title)
        }
        if (credit.media_type === 'tv') {
            return Utils.getTvShowUrlByIdAndName(credit.id, credit.name)
        }
        return 'unknown'
    }

    public static getMovieOrTvShowImageUrlByCredit(credit: PersonCombinedCreditsResponseCrew | PersonCombinedCreditsResponseCast, size: number = 2): string | undefined {
        if (!('poster_path' in credit)) {
            return undefined
        }
        const movieDbConfig = Utils.getMovieDbConfig()
        return movieDbConfig.images.secure_base_url + movieDbConfig.images.poster_sizes[size] + credit.poster_path
    }

    public static getMovieOrTvShowTitleByCredit(credit: PersonCombinedCreditsResponseCrew | PersonCombinedCreditsResponseCast): string {
        if ('title' in credit) {
            return credit.title
        }
        if ('name' in credit) {
            return credit.name
        }
        return 'unknown'
    }

    public static getMovieOrTvShowReleaseDateStringByCredit(credit: PersonCombinedCreditsResponseCrew | PersonCombinedCreditsResponseCast): string | undefined {
        if ('release_date' in credit) {
            return credit.release_date
        }
        if ('first_air_date' in credit) {
            return credit.first_air_date
        }
        return undefined
    }

    public static getMovieOrTvShowReleaseYearByCredit(credit: PersonCombinedCreditsResponseCrew | PersonCombinedCreditsResponseCast): number | undefined {
        const releaseDate = this.getMovieOrTvShowReleaseDateStringByCredit(credit);
        if (!releaseDate) {
            return undefined;
        }
        return DateTime.fromISO(releaseDate).year;
    }

    public static groupCreditsByYear(credits: Array<PersonCombinedCreditsResponseCrew> | Array<PersonCombinedCreditsResponseCast>): Array<PersonCombinedCreditsResponseCrew> | Array<PersonCombinedCreditsResponseCast> {
        const creditsFilledWithYears = Utils.fillCreditYears(credits)
        return groupByKey(creditsFilledWithYears, 'year', {omitKey: true})
    }

    public static fillCreditYears(credits: Array<PersonCombinedCreditsResponseCrew> | Array<PersonCombinedCreditsResponseCast>): Array<PersonCombinedCreditsResponseCrew & { year: number | null }> | Array<PersonCombinedCreditsResponseCast & { year: number | null }> {
        return credits.map(credit => {
            let tmpCredit = {...credit}
            let year = Utils.getMovieOrTvShowReleaseYearByCredit(credit)
            tmpCredit['year'] = year ? year : null
            return tmpCredit
        })
    }

    public static getPersonImageUrl(person: Person & { combined_credits: PersonCombinedCreditsResponse, images: PersonImagesResponse }, size: number = 2): string {
        const movieDbConfig = Utils.getMovieDbConfig()
        return movieDbConfig.images.secure_base_url + movieDbConfig.images.poster_sizes[size] + person.profile_path
    }

    public static getYearsSinceDateString(dateString: string): number {
        // return differenceInYears(new Date(), Date.parse(dateString))
        return this.getYearsBetweenDateStrings(DateTime.now().toString(), dateString);
    }

    public static getYearsBetweenDateStrings(dateString1: string, dateString2: string): number {
        // return Math.abs(differenceInYears(Date.parse(dateString1), Date.parse(dateString2)))
        return Math.floor(Math.abs(DateTime.fromISO(dateString1).diff(DateTime.fromISO(dateString2), 'years').years));
    }

    public static getLocalizedMovieReleaseInformations(movie: MovieResponse & { release_dates: MovieReleaseDatesResponse }): {
        iso31661Locale: string;
        releaseDateString: string;
        certification?: string;
    } | undefined {

        if (!('results' in movie.release_dates)) {
            return undefined;
        }

        let iso31661Locale = Utils.getIso31661Locale()
        let releaseDates = Utils.getReleaseDatesByIso31661Locale(movie.release_dates.results, iso31661Locale)

        /*
        // try to get release date by production country
        if (!releaseDates) {
            if ('production_countries' in movie) {
                let productionCountry = movie.production_countries.find(productionCountry => 'iso_3166_1' in productionCountry)
                if (productionCountry) {
                    iso31661Locale = movie.production_countries[0].iso_3166_1
                    releaseDates = Utils.getReleaseDatesByIso31661Locale(movie.release_dates.results, iso31661Locale)
                }
            }
        }

        // try to get release date by production company
        if (!releaseDates) {
            if ('production_companies' in movie) {
                let productionCompany = movie.production_companies.find(productionCompany => 'origin_country' in productionCompany)
                if (productionCompany) {
                    iso31661Locale = productionCompany.origin_country
                    releaseDates = Utils.getReleaseDatesByIso31661Locale(movie.release_dates.results, iso31661Locale)
                }
            }
        }
        */

        if (!releaseDates) {
            return undefined;
        }

        // popular release date (i used 'releaseDateTypes' const values from above)
        let releaseDate = releaseDates.find(releaseDate => 'type' in releaseDate && [3, 4, 5, 6].includes(releaseDate.type))
        if (releaseDate === undefined) {
            releaseDate = releaseDates[0]
        }

        return {
            iso31661Locale: iso31661Locale,
            releaseDateString: releaseDate.release_date,
            certification: releaseDate.certification,
        }

        /*
        const reversedReleaseDates = releaseDates.reverse();

        let finalReleaseDate = null
        for (let releaseDate of reversedReleaseDates) {
            if (finalReleaseDate === null) {
                finalReleaseDate = releaseDate
            } else if ('type' in releaseDate && [3, 4, 5, 6].includes(releaseDate.type)) {
                finalReleaseDate = releaseDate
            }
            finalReleaseDate = releaseDate
        }

        return null;
        // const releaseDates = Utils.getLocalizedMovieReleaseDates(movie)
        // return releaseDates !== null ? releaseDates[0] : null
        */
    }

    public static getReleaseDatesByIso31661Locale(
            releaseDateLocaleGroups: Array<{ iso_3166_1?: string; release_dates?: Array<ReleaseDate>; }>,
            iso31661Locale: string,
    ): Array<ReleaseDate> | undefined {
        /*
        for (let releaseDateLocaleGroup of releaseDateLocaleGroups) {
            if (!('iso_3166_1' in releaseDateLocaleGroup) || !('release_dates' in releaseDateLocaleGroup)) {
                continue
            }
            if (releaseDateLocaleGroup.iso_3166_1 === iso31661Locale) {
                return releaseDateLocaleGroup.release_dates
            }
        }
        return null
        */
        return releaseDateLocaleGroups
                .filter(releaseDateLocaleGroup => 'iso_3166_1' in releaseDateLocaleGroup && 'release_dates' in releaseDateLocaleGroup)
                .find(releaseDateLocaleGroup => releaseDateLocaleGroup.iso_3166_1 === iso31661Locale)
                ?.release_dates
    }

    // this method returns the iso31661Locale string, if language is not found in the config!
    public static getEnglishLanguageNameByIso6391Locale(iso31661Locale: string): string {
        const language = Utils.getMovieDbLanguageConfig().find(language => language.iso_639_1 === iso31661Locale)
        return language ? language.english_name : iso31661Locale
    }

    // this method returns the iso31661Locale string, if language is not found in the config!
    public static getNativeLanguageNameByIso6391Locale(iso31661Locale: string): string {
        const language = Utils.getMovieDbLanguageConfig().find(language => language.iso_639_1 === iso31661Locale)
        return language ? language.name : Utils.getEnglishLanguageNameByIso6391Locale(iso31661Locale)
    }

    public static getMovieDbConfig(): ConfigurationResponse {
        // this is currently hardcoded into next.config.js
        return publicRuntimeConfig.movieDbConfig
    }

    public static getMovieDbLanguageConfig(): Array<Language> {
        // this is currently hardcoded into next.config.js
        return publicRuntimeConfig.movieDbLanguageConfig
    }

    public static getLocale(): string {
        return GetLocale()
    }

    // language locale getter: hu-HU -> hu
    public static convertNextLocaleToIso6391Locale(locale: string): string {
        return locale.split('-')[0]
    }

    // country locale getter: hu-HU -> HU
    public static convertNextLocaleToIso31661Locale(locale: string): string {
        return locale.split('-')[1]
    }

    // HU
    public static getIso31661Locale(): string {
        return Utils.getLocale().split('-')[1]
    }

    public static handleHorizontalSliderScroll(e): void {

        const visibleContainerWidth = e.target.clientWidth;
        const fullContainerWidth = e.target.scrollWidth;
        const scrollFromLeft = e.target.scrollLeft;

        // console.log('visibleContainerWidth', visibleContainerWidth)
        // console.log('fullContainerWidth', fullContainerWidth)
        // console.log('scrollFromLeft', scrollFromLeft)

        const doNotFade = visibleContainerWidth + scrollFromLeft === fullContainerWidth; // full scroll
        // const doNotFade = visibleContainerWidth + scrollFromLeft > fullContainerWidth - 200; // this is bad if scrolling when scrolling to left within 200px
        const parentElement = e.target.parentNode;
        if (doNotFade) {
            if (!parentElement.classList.contains('doNotFade')) {
                parentElement.classList.add('doNotFade');
            }
        } else {
            if (parentElement.classList.contains('doNotFade')) {
                parentElement.classList.remove('doNotFade');
            }
        }
    }

    // get tmdb languages, while exclude xx, because that means no language
    public static getAllTmdbIso31661Locale() {
        return Utils.getMovieDbLanguageConfig().map(language => language.iso_639_1).filter(language => language !== 'xx').sort()
    }

    // do not use regex, to prevent redos (regular expression ddos) attack:
    // https://stackoverflow.com/a/6680877/4510591
    // TODO: remove this method, if not using
    public static removeAllTrailingSlashes(text: string): string {
        let i = text.length;
        while (text[--i] === '/') {
            text.slice(0, i + 1);
        }
        return text;
    }

    public static getCurrentLocaleWatchProviders(watchProviders: WatchProvider[]): WatchProvider[] {
        return watchProviders.filter((watchProvider) => this.isWatchProviderPopularAtCurrentLocale(watchProvider));
    }

    public static orderWatchProvidersByDisplayPriority(watchProviders: WatchProvider[]): WatchProvider[] {
        // console.log(this.getIso31661Locale());
        // console.log('getWatchProviderDisplayPriority', watchProviders[0]);
        watchProviders.sort((watchProvider1, watchProvider2) => {
            return this.getWatchProviderDisplayPriority(watchProvider1) - this.getWatchProviderDisplayPriority(watchProvider2)
        });

        // for (let i = 0; i < watchProviders.length; i++) {
        //     const displayPriority = this.getWatchProviderDisplayPriority(watchProviders[i]);
        //     console.log('priority by for ' + watchProviders[i].provider_name + ': ', displayPriority);
        // }

        return watchProviders;
    }

    // '| any' was needed because the build errored because of undefined display_priorities
    private static getWatchProviderDisplayPriority(watchProvider: WatchProvider | any): number {
        // parameter not found in https://github.com/grantholle/moviedb-promise
        // noinspection TypeScriptUnresolvedVariable
        const displayPriorities = watchProvider.display_priorities as WatchProviderDisplayPriorities;

        const iso31661Locale = Utils.getIso31661Locale();

        if (iso31661Locale in displayPriorities) {
            // console.log('priority by ' + iso31661Locale + ' locale for ' + watchProvider.provider_name + ': ', displayPriorities[iso31661Locale]);
            return displayPriorities[iso31661Locale];
        }

        const displayPrioritiesArray = Object.values(displayPriorities);

        let averagePriority: number;
        if (displayPrioritiesArray.length > 0) {
            // in this case in the current locale it's not popular
            averagePriority = 5000 + this.round(this.getAverage(displayPrioritiesArray), 2);
        } else {
            // no info
            averagePriority = 10000;
        }

        // console.log('priority by average for ' + watchProvider.provider_name + ': ', averagePriority);
        return averagePriority;
    }

    // '| any' was needed because the build errored because of undefined display_priorities
    private static isWatchProviderPopularAtCurrentLocale(watchProvider: WatchProvider | any): boolean {
        // parameter not found in https://github.com/grantholle/moviedb-promise
        // noinspection TypeScriptUnresolvedVariable
        const displayPriorities = watchProvider.display_priorities as WatchProviderDisplayPriorities;
        const iso31661Locale = Utils.getIso31661Locale();
        return iso31661Locale in displayPriorities;
    }

    private static getAverage(array: number[]): number {
        return array.reduce((a, b) => a + b) / array.length;
    }

    private static round(value: number, decimals: number): number {
        return +(value.toFixed(decimals));
    }
}

// TODO: modify and use this image loader if needed
// TODO: a) different image sizes should be supported in this method and the 'sizes' parameter at the image components should be filled
// TODO: b) alternatively, use unoptimizied parameter on image components
// TODO: c) disable image optimization completely with the image.unoptimized parameter in the next.config.js
export const myLoader = ({src, width, quality}) => {
    // return `https://example.com/${src}?w=${width}&q=${quality || 75}`
    // return `https://cdn.mos.cms.futurecdn.net/ETb2xLjvc62eb7PPLspSsU.jpg`
    return src
}

/**
 * https://stackoverflow.com/a/7592235/4510591
 * Capitalizes first letters of words in string.
 * @param {string} str String to be modified
 * @param {boolean=false} lower Whether all other letters should be lowercased
 * @return {string}
 * @usage
 *   capitalize('fix this string');     // -> 'Fix This String'
 *   capitalize('javaSCrIPT');          // -> 'JavaSCrIPT'
 *   capitalize('javaSCrIPT', true);    // -> 'Javascript'
 */
export const capitalizeWords = (str: string, lower: boolean = false) => (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase());

/**
 * I created this.
 * Capitalizes first letter first word in string.
 * @param {string} str String to be modified
 * @param {boolean=false} lower Whether all other letters should be lowercased
 * @return {string}
 * @usage
 *   capitalize('fix this string');     // -> 'Fix this string'
 *   capitalize('javaSCrIPT');          // -> 'JavaSCrIPT test'
 *   capitalize('javaSCrIPT', true);    // -> 'Javascript test'
 */
export const capitalizeFirstLetter = (str: string, lower: boolean = false) => {
    const newStr = lower ? str.toLowerCase() : str
    return newStr.charAt(0).toUpperCase() + newStr.slice(1)
}

// https://stackoverflow.com/a/47385953/4510591
// export const groupByKey = (list, key) => list.reduce((hash, obj) => ({...hash, [obj[key]]: (hash[obj[key]] || []).concat(obj)}), {})
export const groupByKey = (list, key, {omitKey = false}) => list.reduce((hash, {[key]: value, ...rest}) => ({
    ...hash,
    [value]: (hash[value] || []).concat(omitKey ? {...rest} : {[key]: value, ...rest}),
}), {})
