import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faFacebookF, faTwitter} from '@fortawesome/free-brands-svg-icons'
import Image from 'next/image';

export default function Footer() {

    const currentYear = new Date().getFullYear();

    return (
        <footer className="text-center mt-4" style={{backgroundColor: '#f1f1f1'}}>
            {/* * /}
            <div className="container">
                <section>
                    <a
                        className="btn btn-link btn-floating btn-lg m-1"
                        href="#!"
                        role="button"
                        data-mdb-ripple-color="dark"
                    >
                        <FontAwesomeIcon icon={faFacebookF}/>

                    </a>
                    <a
                        className="btn btn-link btn-floating btn-lg m-1"
                        href="#!"
                        role="button"
                        data-mdb-ripple-color="dark"
                    >
                        <FontAwesomeIcon icon={faTwitter}/>
                    </a>
                </section>
            </div>
            {/**/}

            <div className="container">
                <section>
                    <a
                        className="btn btn-link btn-floating btn-lg m-1"
                        href="https://www.themoviedb.org"
                        role="button"
                        data-mdb-ripple-color="dark"
                        target={'_blank'}
                        rel={'noopener noreferrer'}
                    >
                        <Image
                            src="/igdb_logo/blue_short.svg"
                            width={100}
                            height={30}
                            alt="IGDB"
                            loading={'lazy'}
                        />
                    </a>
                </section>
            </div>

            <div className="text-center p-3 fw-bold" style={{backgroundColor: 'rgba(0, 0, 0, 0.2)'}}>
                © {currentYear} Lindion -
                <a
                    href={'https://www.termsfeed.com/live/8272864c-ab02-485f-9ec5-05ed7c5ab7b2'}
                    target={'_blank'}
                    rel={'noopener noreferrer'}
                    style={{textDecoration: 'none'}}
                >
                    &nbsp;Privacy policy {/* TODO: translation! */}
                </a>
            </div>

        </footer>
    )
}
