
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import '../styles/globals.scss';
import type { AppProps } from 'next/app';
import Layout from '../components/Layout';
import { useRouter } from 'next/router';
import { config } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';
config.autoAddCss = false;
//// a) moment
// import moment from 'moment';
// import 'moment/min/locales'; // load all locales this one time, because it's needed for locale set!
//// b) dayjs
// import dayjs from 'dayjs';
// import localizedFormat from 'dayjs/plugin/localizedFormat';
// import relativeTime from 'dayjs/plugin/relativeTime';
// import duration from 'dayjs/plugin/duration';
// // this list was generated by getLocales.php
// import 'dayjs/locale/af'
// import 'dayjs/locale/ar'
// import 'dayjs/locale/ar-SA'
// import 'dayjs/locale/be'
// import 'dayjs/locale/bg'
// import 'dayjs/locale/bn-BD'
// import 'dayjs/locale/ca'
// // locale not found: ch-GU
// // locale not found: cn-CN
// import 'dayjs/locale/cs'
// import 'dayjs/locale/cy'
// import 'dayjs/locale/da'
// import 'dayjs/locale/de-AT'
// import 'dayjs/locale/de-CH'
// import 'dayjs/locale/de'
// import 'dayjs/locale/el'
// import 'dayjs/locale/en-AU'
// import 'dayjs/locale/en-CA'
// import 'dayjs/locale/en-GB'
// import 'dayjs/locale/en-IE'
// import 'dayjs/locale/en-NZ'
// import 'dayjs/locale/en'
// import 'dayjs/locale/eo'
// import 'dayjs/locale/es'
// import 'dayjs/locale/es-MX'
// import 'dayjs/locale/et'
// import 'dayjs/locale/eu'
// import 'dayjs/locale/fa'
// import 'dayjs/locale/fi'
// import 'dayjs/locale/fr-CA'
// import 'dayjs/locale/fr'
// import 'dayjs/locale/ga'
// import 'dayjs/locale/gd'
// import 'dayjs/locale/gl'
// import 'dayjs/locale/he'
// import 'dayjs/locale/hi'
// import 'dayjs/locale/hr'
// import 'dayjs/locale/hu'
// import 'dayjs/locale/id'
// import 'dayjs/locale/it'
// import 'dayjs/locale/ja'
// import 'dayjs/locale/ka'
// import 'dayjs/locale/kk'
// import 'dayjs/locale/kn'
// import 'dayjs/locale/ko'
// import 'dayjs/locale/ky'
// import 'dayjs/locale/lt'
// import 'dayjs/locale/lv'
// import 'dayjs/locale/ml'
// import 'dayjs/locale/mr'
// import 'dayjs/locale/ms-MY'
// import 'dayjs/locale/ms'
// import 'dayjs/locale/nb'
// import 'dayjs/locale/nl-BE'
// import 'dayjs/locale/nl'
// // locale not found: no-NO
// import 'dayjs/locale/pa-IN'
// import 'dayjs/locale/pl'
// import 'dayjs/locale/pt-BR'
// import 'dayjs/locale/pt'
// import 'dayjs/locale/ro'
// import 'dayjs/locale/ru'
// import 'dayjs/locale/si'
// import 'dayjs/locale/sk'
// import 'dayjs/locale/sl'
// import 'dayjs/locale/sq'
// import 'dayjs/locale/sr'
// import 'dayjs/locale/sv'
// import 'dayjs/locale/ta'
// import 'dayjs/locale/te'
// import 'dayjs/locale/th'
// import 'dayjs/locale/tl-PH'
// import 'dayjs/locale/tr'
// import 'dayjs/locale/uk'
// import 'dayjs/locale/vi'
// import 'dayjs/locale/zh-CN'
// import 'dayjs/locale/zh-HK'
// import 'dayjs/locale/zh'
// import 'dayjs/locale/zh-TW'
// // locale not found: zu-ZA
/* use luxon instead of date-fns
//// c) date-fns
// optimal import for smaller size: https://github.com/date-fns/date-fns/issues/2188#issue-802307126
// format info: https://date-fns.org/v2.23.0/docs/format
import {setDefaultOptions} from 'date-fns';

import {
    // enUS,
    // ru,
    af,
    ar,
    arSA,
    be,
    bg,
    bn,
    ca,
    // locale not found: ch-GU
    // locale not found: cn-CN
    cs,
    cy,
    da,
    deAT,
    de,
    el,
    enAU,
    enCA,
    enGB,
    enIE,
    enNZ,
    enUS,
    eo,
    es,
    et,
    eu,
    faIR,
    fi,
    frCA,
    fr,
    // locale not found: ga-IE
    gd,
    gl,
    he,
    hi,
    hr,
    hu,
    id,
    it,
    ja,
    ka,
    kk,
    kn,
    ko,
    // locale not found: ky-KG
    lt,
    lv,
    // locale not found: ml-IN
    // locale not found: mr-IN
    ms,
    nb,
    nlBE,
    nl,
    // locale not found: no-NO
    // locale not found: pa-IN
    pl,
    ptBR,
    pt,
    ro,
    ru,
    // locale not found: si-LK
    sk,
    sl,
    sq,
    sr,
    sv,
    ta,
    te,
    th,
    // locale not found: tl-PH
    tr,
    uk,
    vi,
    zhCN,
    zhHK,
    // locale not found: zh-SG
    zhTW,
    // locale not found: zu-ZA
} from 'date-fns/locale';

// this list was generated by getLocales.php
const dateFnsLocales = {
    // 'en-US': enUS,
    // 'ru-RU': ru,
    'af-ZA': af,
    'ar-AE': ar,
    'ar-SA': arSA,
    'be-BY': be,
    'bg-BG': bg,
    'bn-BD': bn,
    'ca-ES': ca,
    'cs-CZ': cs,
    'cy-GB': cy,
    'da-DK': da,
    'de-AT': deAT,
    'de-CH': de,
    'de-DE': de,
    'el-GR': el,
    'en-AU': enAU,
    'en-CA': enCA,
    'en-GB': enGB,
    'en-IE': enIE,
    'en-NZ': enNZ,
    'en-US': enUS,
    'eo-EO': eo,
    'es-ES': es,
    'es-MX': es,
    'et-EE': et,
    'eu-ES': eu,
    'fa-IR': faIR,
    'fi-FI': fi,
    'fr-CA': frCA,
    'fr-FR': fr,
    'gd-GB': gd,
    'gl-ES': gl,
    'he-IL': he,
    'hi-IN': hi,
    'hr-HR': hr,
    'hu-HU': hu,
    'id-ID': id,
    'it-IT': it,
    'ja-JP': ja,
    'ka-GE': ka,
    'kk-KZ': kk,
    'kn-IN': kn,
    'ko-KR': ko,
    'lt-LT': lt,
    'lv-LV': lv,
    'ms-MY': ms,
    'ms-SG': ms,
    'nb-NO': nb,
    'nl-BE': nlBE,
    'nl-NL': nl,
    'pl-PL': pl,
    'pt-BR': ptBR,
    'pt-PT': pt,
    'ro-RO': ro,
    'ru-RU': ru,
    'sk-SK': sk,
    'sl-SI': sl,
    'sq-AL': sq,
    'sr-RS': sr,
    'sv-SE': sv,
    'ta-IN': ta,
    'te-IN': te,
    'th-TH': th,
    'tr-TR': tr,
    'uk-UA': uk,
    'vi-VN': vi,
    'zh-CN': zhCN,
    'zh-HK': zhHK,
    'zh-TW': zhTW,
};
const defaultDateFnsLocale = enUS;
/**/
import { Settings } from 'luxon';
function MyApp({ Component, pageProps }: AppProps) {
    const { locale } = useRouter();
    //// moment
    // always display durations in minutes https://momentjs.com/docs/#/customization/relative-time-threshold/
    // moment.locale(locale);
    // moment.relativeTimeThreshold('m', Number.MAX_VALUE);
    //// dayjs
    // dayjs.extend(localizedFormat)
    // dayjs.extend(relativeTime)
    // dayjs.extend(duration)
    // dayjs.locale(locale)
    //// date-fns
    // use luxon instead of date-fns
    // setDefaultOptions({locale: locale in dateFnsLocales ? dateFnsLocales[locale] : defaultDateFnsLocale});
    // console.log('locale', locale);
    Settings.defaultLocale = locale;
    return (<Layout>
                <Component {...pageProps}/>
            </Layout>);
}

    export default __appWithI18n(MyApp, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  