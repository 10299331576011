import useTranslation from 'next-translate/useTranslation'

import Head from 'next/head';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import {useRouter} from 'next/router';
import Link from 'next/link'
import Utils, {capitalizeFirstLetter, capitalizeWords} from '../utils/Utils';
import Script from 'next/script'
import {Fragment} from 'react';

export default function Header() {

    const {locale, locales, defaultLocale, asPath, reload, pathname, query, push} = useRouter();

    const {t, lang} = useTranslation('common')

    const currentLocale = locale;

    let pathToAdd = asPath === '/' ? '' : asPath; // do not add trailing slash to home screen, because it causes redirect

    return (
        <>
            <Head>
                <title>{capitalizeWords(`Lindion - ${t('movie database')}`)}</title>
                <meta property="og:title" key={'ogTitle'}
                      content={capitalizeWords(`Lindion - ${t('movie database')}`)}/>
                <meta name="description"
                      content={t('Any movie, series, TV show, or actor you can\'t find here doesn\'t even exist.')}/>
                <meta property="og:description" key={'ogDescription'}
                      content={t('Any movie, series, TV show, or actor you can\'t find here doesn\'t even exist.')}/>
                <meta property="og:image" key={'ogImage'} content="https://www.lindion.com/logo.png"/>

                <meta property="og:site_name" key={'siteName'} content="Lindion"/>
                <meta property="og:locale" key={'ogLocale'} content={locale.replace('-', '_')}/>

                <meta name="apple-mobile-web-app-title" content="Lindion"/>
                <meta name="application-name" content="Lindion"/>

                <meta name="robots" content="index, follow, max-image-preview:large"/>

                <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png"/>
                <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"/>
                <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"/>
                <link rel="manifest" href="/site.webmanifest"/>

                {/*{currentLocale !== 'en-US' &&*/}
                {/*    <link key={'canonical'} rel="canonical" href={`https://www.lindion.com${currentAsPathWithoutTrailingSlash}`}/>*/}
                {/*}*/}

                {/*
                Define canonical on every page for the same page!
                https://www.portent.com/blog/seo/implement-hreflang-canonical-tags-correctly.htm
                TODO: Add non-region hreflang alternates for en, af, ar, be, bg, bn, ca, ch, cn, cs, cy, da, de, el, eo, es, et, eu, fa, fi, fr, ga, gd, gl, he, hi, hr, hu, id, it, ja, ka, kk, kn, ko, ky, lt, lv, ml, mr, ms, nb, nl, no, pa,
                */}
                <link key={'canonical'} rel="canonical" href={`https://www.lindion.com/${locale}${pathToAdd}`}/>

                <link rel="alternate" hrefLang="x-default" href={`https://www.lindion.com${pathToAdd}`}/>
                {locales.map((locale) => {
                    const url = (locale === 'en-US' ? `https://www.lindion.com${pathToAdd}` : `https://www.lindion.com/${locale}${pathToAdd}`)
                    return (
                        <Fragment key={locale}>
                            <meta property="og:locale:alternate" content={locale.replace('-', '_')}/>
                            <link rel="alternate" hrefLang={locale} href={url}/>
                        </Fragment>
                    )
                })}
            </Head>

            {/* Google Analytics - this should be not in the Head tag! - https://nextjs.org/docs/messages/next-script-for-ga#using-gtagjs */}
            {/*<Script*/}
            {/*    src="https://www.googletagmanager.com/gtag/js?id=G-CLVP488JT3"*/}
            {/*    strategy="afterInteractive"*/}
            {/*/>*/}
            {/*<Script id="google-analytics" strategy="afterInteractive">*/}
            {/*    {`*/}
            {/*        window.dataLayer = window.dataLayer || [];*/}
            {/*        function gtag(){window.dataLayer.push(arguments);}*/}
            {/*        gtag('js', new Date());*/}
            {/*        */}
            {/*        gtag('config', 'G-CLVP488JT3');*/}
            {/*        `}*/}
            {/*</Script>*/}

            {[/* false, 'sm', 'md', 'lg', 'xl', 'xxl' */ 'md'].map((expand) => (
                <Navbar key={expand} bg="light" expand={/* expand */ true} className="mb-3 fw-bold" fixed={'top'}
                        sticky={'top'}>
                    <Container>
                        <Link href="/" style={{textDecoration: 'none'}}>
                            <Navbar.Brand style={{
                                letterSpacing: '6px',
                                fontWeight: 900,
                            }}>{'LINDION'}</Navbar.Brand>
                        </Link>
                        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`}/>
                        <Navbar.Offcanvas
                            id={`offcanvasNavbar-expand-${expand}`}
                            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                            placement="end"
                        >
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                    Menu
                                </Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                {/* * /}
                                <Nav className="justify-content-end flex-grow-1 pe-3">
                                    <NavDropdown
                                        title={capitalizeWords(t('movies'))}
                                        id={`movie-offcanvasNavbarDropdown-expand-${expand}`}
                                    >
                                        <NavDropdown.Item href="#action3">{capitalizeWords(t('popular'))}</NavDropdown.Item>
                                        <NavDropdown.Item href="#action4">{capitalizeWords(t('now playing'))}</NavDropdown.Item>
                                        <NavDropdown.Item href="#action4">{capitalizeWords(t('upcoming'))}</NavDropdown.Item>
                                        <NavDropdown.Item href="#action4">{capitalizeWords(t('top rated'))}</NavDropdown.Item>
                                    </NavDropdown>

                                    <NavDropdown
                                        title={capitalizeWords(t('TV shows'))}
                                        id={`tvShow-offcanvasNavbarDropdown-expand-${expand}`}
                                    >
                                        <NavDropdown.Item href="#action3">{capitalizeWords(t('popular'))}</NavDropdown.Item>
                                        <NavDropdown.Item href="#action4">{capitalizeWords(t('airing today'))}</NavDropdown.Item>
                                        <NavDropdown.Item href="#action4">{capitalizeWords(t('on TV'))}</NavDropdown.Item>
                                        <NavDropdown.Item href="#action4">{capitalizeWords(t('top rated'))}</NavDropdown.Item>
                                    </NavDropdown>
                                </Nav>
                                {/**/}


                                <Nav className="justify-content-end flex-grow-1">
                                    {/* * /}
                                    <Form className="d-flex">
                                        <Form.Control
                                            type="search"
                                            placeholder={capitalizeWords(t('search'))}
                                            className={'me-2 fw-bold'}
                                            aria-label={capitalizeWords(t('search'))}
                                        />
                                        <Button className={'me-2 fw-bold'}>{capitalizeWords(t('search'))}</Button>
                                    </Form>
                                    {/**/}

                                    <Form className="d-flex">
                                        <Form.Select
                                            className={'me-2'}
                                            aria-label="Language selector"
                                            value={currentLocale}
                                            onChange={(event) => {
                                                const newLocale = event.target.value;
                                                const expirationInMilliseconds = 365 * 24 * 60 * 60 * 1000 // 365 days
                                                const date = new Date
                                                date.setTime(date.getTime() + expirationInMilliseconds)
                                                document.cookie = `NEXT_LOCALE=${newLocale}; expires=${date.toUTCString()}; path=/`
                                                push({pathname, query}, asPath, {locale: newLocale})
                                            }}
                                        >
                                            {locales.map((locale, idx) => (
                                                <option key={idx} value={locale}>{locale}</option>
                                            ))}
                                        </Form.Select>
                                    </Form>
                                </Nav>
                            </Offcanvas.Body>
                        </Navbar.Offcanvas>
                    </Container>
                </Navbar>
            ))}

            {/* * /}
            <nav className="navbar navbar-expand-lg bg-light navbar-light ">
                <div className="container">

                    <a className="navbar-brand" href="#">Brand</a>

                    <button className="navbar-toggler" type="button" data-mdb-toggle="collapse" data-mdb-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <i className="fas fa-bars"></i>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">

                        </ul>

                        <form className="w-auto">
                            <input type="search" className="form-control" placeholder="Type query" aria-label="Search"/>
                        </form>

                    </div>
                </div>
            </nav>
            {/**/}
        </>
    )
}
